:root {
  --theme-green: rgb(82 181 184);
  --theme-light-green: rgb(213 237 211);
  --theme-pink: rgb(255, 123, 145);
  --theme-purple: rgb(98, 122, 216);
  --theme-medium-gray: rgb(178 188 202);
  --theme-light-gray: rgb(238 242 246);
  --theme-plum: rgb(72 29 60);
  --theme-title-font-size: 1.3rem;
  background-color: var(--theme-light-gray);
  color: gray;
  margin: 0 auto;
}

body {
  font-size: clamp(16px, 1.2vw, 36px);
  line-height: 1.5;
  width: min(768px, calc(100%));
  margin: 0 auto;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
